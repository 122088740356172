@import '../_colors.scss';
@import '../_fonts.scss';

.job-card {
    text-align: left;
    border-radius: 10px;
    padding: 0 10px 10px 10px;
    margin: 0 10px 10px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 2px solid transparent;
    position: relative;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }

    &.selected {
        border: 3px solid #002b7f;
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.1);
            pointer-events: none;
            border-radius: 8px;
        }

        .job-card-body {
            background-color: #c0d4f5;
        }
    }

    h3 {
        font-size: 1em;
        margin: 0;
    }

    .status {
        font-size: 0.9em;
        font-style: italic;
    }
}

.job-card-header {
    color: #ffffff;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job-card-body {
    background-color: #d9e8ff;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.job-info {
    flex-grow: 1;
    text-align: left;
    p {
        margin: 5px 0;
        font-size: 0.9em;
        color: #333;
    }
}

.job-card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    flex-shrink: 0;
}

.date-info {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    color: #002b7f;
}

.calendar-icon {
    margin-right: 5px;
    font-size: 1.2em;
    color: #002b7f;
}
