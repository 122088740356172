@import '../_colors.scss';
@import '../_fonts.scss';

.purchase-order-module-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.purchase-order-module {
    flex: 1;
    padding: 2rem;
    background-color: $main_contend_pri_color;
    
    h1 {
      color: $top_butt_bar_pri_color;
      font-family: $font_base;
      font-size: 2.5rem;
      margin-bottom: 2rem;
      text-align: left;
    }
    
    // ------------- Estilos para el serach
    .action-container {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
  
      .MuiTextField-root {
        width: 100%;
    
        .MuiOutlinedInput-root {
          background-color: $table_row_color;
          border-radius: 30px;
          height: 40px;
          padding-right: 0; 
          border-color: darken($table_row_color, 25%);
          
          &:hover {
            border-color: #ffffff;
          }
    
          .MuiInputBase-input {
            color: black;
          }
        }
      }
  
      .search-button {
        color: rgb(255, 0, 0);
        color: white;
      }
    
      .edit-button {
        background-color: $button_edit_pri_color;
        border-radius: 5px;
        margin-left: 1rem;
        &:hover {
          background-color: darken($button_edit_pri_color, 25%);
        }
      }
    
      .delete-button {
        background-color: $button_delete_pri_color;
        border-radius: 5px;
        margin-left: 1rem;
        &:hover {
          background-color: darken($button_delete_pri_color, 10%);
        }
      }
    
      .add-button {
        background-color: $button_create_pri_color;
        border-radius: 5px;
        margin-left: 1rem;
        &:hover {
          background-color: darken($button_create_pri_color, 20%);
        }
      }
    
      .MuiIconButton-root img {
        width: 30px;
        height: 30px;
      }
    }
    // ------------- Estilos para la tabla
    .purchase-orders-table-container {
      max-height: 500px;
      overflow-y: auto;
      border-radius: 10px;
    
      .purchase-orders-table {
        border-collapse: separate;
      }
    
      .MuiTableHead-root {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: $table_header;
      }
    
      .MuiTableCell-root {
        padding: 18px;
        color: $table_header_text;
      }
    }
  
    // encabezados de la tabla
    .MuiTableHead-root {
      background-color: $table_header;
  
      .MuiTableCell-root {
        color: $table_header_text;
        font-weight: bold; 
        font-size: 1.1rem;
        padding: 18px;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        background-color: $table_row_color;
        cursor: pointer;
  
        &:hover {
          background-color: $table_row_hover;
        }
  
        &.selected {
          background-color: $table_row_selected;
        }
  
        .MuiTableCell-root {
          color: $table_row_text_color;
          border-bottom: none;
          font-size: 1rem;
          padding: 9px;
        }
        
        .MuiTableCell-root {
          padding-left: 18px;
        }
  
        .role-badge span{
          background-color: $user_role_badge_color;
          color: $user_role_badge_text_color;
          padding: 0.3rem 0.8rem;
          border-radius: 15px;
          display: inline-block;
        }
      }
    }
  }