@import './_colors.scss';
@import './_fonts.scss';

.App {
  text-align: center;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  width: 30px;
  background-color: $side_bar_pri_color;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: $top_butt_bar_pri_color;
  font-family: $font_base;
  text-align: left;
  height: 30px;
  color: white;
  justify-content: space-between; 
  align-items: center;
  display: flex;
  padding: 0 1rem;

  h1 {
    margin: 0;
    font-size: 12px;
    padding: 0.5rem;
  }
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;

  .user-name, user-div, .user-role {
    font-size: 1rem;
    color: $text_color;
  }

  .user-role {
    margin-right: 20px;
  }

  .logout-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: transform 0.2s ease;
    
    &:hover {
      transform: scale(1.1);
    }
  }
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: transparent;

  &:hover {
    background-color: $icon_delete_pri_color;
  }

  .logout-icon {
    width: 20px;
    height: 20px;
    fill: #ffffff;
  }
}

main {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.App-footer {
  background-color: $top_butt_bar_pri_color;
  font-family: $font_base;
  text-align: left;
  height: 30px;
  color: white;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  h1 {
    margin: 0;
    font-size: 12px;
    padding: 0.5rem 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  span {
    flex-shrink: 0;
  }
}

.footer-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.time-display {
  margin-left: auto;
}

