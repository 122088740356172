@import '../_colors.scss';
@import '../_fonts.scss';

.sidebar {
  width: 50px;
  background-color: $side_bar_pri_color;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: width 0.3s ease;
  overflow: hidden;

  &:hover {
    width: 220px;
  }
}

.sidebar-icon {
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  padding: 10px 15px;
  padding-left: 7.5px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: background-color 0.2s ease, transform 0.2s ease;


  &:hover {
    background-color: $main_contend_pri_color;

    img {
      filter: invert(35%) sepia(87%) saturate(626%) hue-rotate(186deg) brightness(95%) contrast(89%);
    }

    span {
      color: $side_bar_pri_color;
    }
  }

  &.active { // Estilos para el botón activo
    background-color: $main_contend_pri_color;
    img {
      filter: invert(35%) sepia(87%) saturate(626%) hue-rotate(186deg) brightness(95%) contrast(89%);
    }

    span {
      color: $side_bar_pri_color;
    }
  }

  img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    filter: invert(100%) brightness(100%);
  }

  span {
    display: none;
    text-align: left;
    font-family: $font_base;
    font-size: 1rem;
    white-space: nowrap;
  }

  .sidebar:hover & {
    flex-direction: row;
  }

  .sidebar:hover & span {
    display: block;
  }
}
