@import '../_colors.scss';
@import '../_fonts.scss';

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: $main_contend_pri_color;
}

.login-box {
  background-color: $pop_up_alert_wind_pri_color;
  padding: 1.5rem;
  border-radius: 20px;
  color: $text_color;
  width: 483px;
  height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.login-box h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.login-input-group .input-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  label{
    display: block;
    margin-bottom: 0.5rem;
    align-self: flex-start;
  }

  input{
    width: 292px;
    padding: 0.5rem;
    border: none;
    border-radius: 10px;
  }
}

.login-button {
  width: 144px;
  padding: 0.5rem;
  background-color: $button_strong_pri_color;
  color: $text_color;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 1rem;
}

.forgot-password {
  font-style: italic;
  text-decoration: underline; 
  display: block;
  text-align: center;
  color: $text_color;
  margin-top: 1rem;
  font-size: 0.9rem;
  &:hover {
    color: darken($text_color, 10%);
  }
}

.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $input_container_pri_color;
  width: 100%;
}

.liftco-logo {
  display: block;
  margin: 0 auto 1.5rem;
  max-width: 80%;
  height: auto;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 20px auto 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
