.quickbooks-success {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;

  .success-content {
    text-align: center;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;
  }

  .checkmark {
    font-size: 48px;
    color: #4CAF50;
    margin-bottom: 1rem;
  }

  h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 1rem;
  }

  p {
    color: #666;
    font-size: 16px;
  }
} 