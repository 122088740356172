.start-job-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .popup-content {
    background-color: #003366;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    color: white;

    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 24px;
    }

    .job-details {
      background-color: #ffffff;
      padding: 15px;
      border-radius: 8px;
      margin-bottom: 20px;
      color: #003366;

      p {
        margin: 8px 0;
      }
    }

    .confirmation-text {
      text-align: center;
      margin: 20px 0;
    }

    .button-container {
      display: flex;
      justify-content: space-around;
      gap: 20px;
      margin-top: 20px;

      button {
        padding: 10px 30px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        min-width: 100px;
        
        &.cancel-btn {
          background-color: #0066cc;
          color: white;
          
          &:hover {
            background-color: #0052a3;
          }
        }

        &.confirm-btn {
          background-color: #ff3b30;
          color: white;
          
          &:hover {
            background-color: #d63029;
          }
        }
      }
    }
  }
} 