@import '../_colors.scss';
@import '../_fonts.scss';

.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: $main_contend_pri_color;
}

.reset-password-form {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f5f5f5; // Off-white color
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h2 {
    color: $top_butt_bar_pri_color;
    font-family: $font_base;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .form-group {
    margin-bottom: 1rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
    }

    input {
      width: 100%;
      padding: 0.5rem;
      font-size: 1rem;
      border: 1px solid $menu_butt_pri_color;
      border-radius: 3px;
    }
  }

  .password-requirements {
    margin-top: 1rem;
    font-size: 0.9rem;

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        margin-bottom: 0.3rem;
        color: #666;

        &.met {
          color: #28a745;
        }
      }
    }
  }

  button {
    width: 100%;
    padding: 0.75rem;
    background-color: $top_butt_bar_pri_color;
    color: white;
    border: none;
    border-radius: 3px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background-color: darken($top_butt_bar_pri_color, 10%);
    }
  }

  .error-message {
    color: red;
    margin-bottom: 1rem;
  }
}
