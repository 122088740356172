@import '../_colors.scss';
@import '../_fonts.scss';

.reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: $main_contend_pri_color;
  

  h2 {
    margin-bottom: 0.5rem;
    font-family: $font_base;
    font-size: 2.2rem;
    color: $text_color; 
  }

  p {
    margin-bottom: 0.5rem;
  }

}

.reset-box {
  background-color: $pop_up_alert_wind_pri_color;
  padding: 1.5rem;
  border-radius: 20px;
  color: $text_color;
  width: 483px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  
}

.reset-input-group .input-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  label{
    display: block;
    margin-bottom: 0.5rem;
    align-self: flex-start;
  }

  input{
    width: 292px;
    padding: 0.5rem;
    border: none;
    border-radius: 10px;
  }
}

.reset-button {
  width: 144px;
  padding: 0.5rem;
  background-color: $button_strong_pri_color;
  color: $text_color;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 1rem;
}

.go-back {
  font-style: italic;
  text-decoration: underline; 
  display: block;
  text-align: center;
  color: $text_color;
  margin-top: 1rem;
  font-size: 0.9rem;
  &:hover {
    color: darken($text_color, 10%);
  }
}

.ok-button {
  width: 144px;
  padding: 0.5rem;
  background-color: $button_weak_pri_color;
  color: $text_color;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 1rem;
}

.reset-box p {
  text-align: center;
  margin-bottom: 1rem;
}
