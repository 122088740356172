@import '../_colors.scss';
@import '../_fonts.scss';

.purchase-order-items-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .purchase-order-items-content {
    background-color: $main_contend_pri_color;
    width: 80%;
    height: 80%;
    border-radius: 10px;
    padding: 2rem;
    overflow-y: auto;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      h2 {
        color: $top_butt_bar_pri_color;
        font-family: $font_base;
        font-size: 2rem;
        margin: 0;
      }

      .close-btn {
        background: none;
        border: none;
        font-size: 2rem;
        cursor: pointer;
        color: $top_butt_bar_pri_color;
        
        &:hover {
          color: darken($top_butt_bar_pri_color, 10%);
        }
      }
    }
  }
  .action-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .MuiTextField-root {
      width: 100%;
  
      .MuiOutlinedInput-root {
        background-color: $table_row_color;
        border-radius: 30px;
        height: 40px;
        padding-right: 0; 
        border-color: darken($table_row_color, 25%);
        
        &:hover {
          border-color: #ffffff;
        }
  
        .MuiInputBase-input {
          color: black;
        }
      }
    }

    .search-button {
      color: rgb(255, 0, 0);
      color: white;
    }
  
    .edit-button {
      background-color: $button_edit_pri_color;
      border-radius: 5px;
      margin-left: 1rem;
      &:hover {
        background-color: darken($button_edit_pri_color, 25%);
      }
    }
  
    .delete-button {
      background-color: $button_delete_pri_color;
      border-radius: 5px;
      margin-left: 1rem;
      &:hover {
        background-color: darken($button_delete_pri_color, 10%);
      }
    }
  
    .add-button {
      background-color: $button_create_pri_color;
      border-radius: 5px;
      margin-left: 1rem;
      &:hover {
        background-color: darken($button_create_pri_color, 20%);
      }
    }
  
    .MuiIconButton-root img {
      width: 30px;
      height: 30px;
    }
  }
  // ------------- Estilos para la tabla
  .purchase-orders-table-container {
    max-height: 500px;
    overflow-y: auto;
    border-radius: 10px;
  
    .purchase-orders-table {
      border-collapse: separate;
    }
  
    .MuiTableHead-root {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: $table_header;
    }
  
    .MuiTableCell-root {
      padding: 18px;
      color: $table_header_text;
    }
  }

  // encabezados de la tabla
  .MuiTableHead-root {
    background-color: $table_header;

    .MuiTableCell-root {
      color: $table_header_text;
      font-weight: bold; 
      font-size: 1.1rem;
      padding: 18px;
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      background-color: $table_row_color;
      cursor: pointer;

      &:hover {
        background-color: $table_row_hover;
      }

      &.selected {
        background-color: $table_row_selected;
      }

      .MuiTableCell-root {
        color: $table_row_text_color;
        border-bottom: none;
        font-size: 1rem;
        padding: 9px;
      }
      
      .MuiTableCell-root {
        padding-left: 18px;
      }

      .role-badge span{
        background-color: $user_role_badge_color;
        color: $user_role_badge_text_color;
        padding: 0.3rem 0.8rem;
        border-radius: 15px;
        display: inline-block;
      }
    }
  }
} 

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  
  .search-result-item {
    padding: 8px 12px;
    cursor: pointer;
    
    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.form-group {
  position: relative;
} 