@import '../_colors.scss';
@import '../_fonts.scss';

.unassigned-job-card {
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .assign-button {
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: darken($button_primary_color, 10%);
    }
  }
}

.filters {
  .MuiTextField-root {
    background-color: white;
    border-radius: 4px;
    .MuiOutlinedInput-root {
      &:hover fieldset {
        border-color: $button_primary_color;
      }
      
      &.Mui-focused fieldset {
        border-color: $button_primary_color;
      }
    }
  }
}

.jobs-grid {
  .job-card {
    transition: transform 0.2s ease;
    cursor: pointer;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
}

// Loading and error states
.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #000000;
}

.error-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #000000;
  font-size: 1.2rem;
}
