.invoice-generation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .invoice-content {
    background-color: #f0f4ff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;

    .invoice-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 2px solid #1a237e;

      h2 {
        color: #1a237e;
        margin: 0;
        padding: 0;
        border: none;
      }

      .header-info {
        display: flex;
        gap: 20px;
        background-color: #5c6bc0;
        color: white;
        border-radius: 4px;
        padding: 10px 15px;
        font-size: 0.9em;

        .client-info, .machinery-info {
          .info-item {
            margin: 2px 0;
            .icon {
              margin-right: 8px;
            }
          }
        }
      }
    }

    .invoice-body {
      display: grid;
      grid-template-columns: 3fr 2fr;
      gap: 20px;
      margin-bottom: 20px;

      .left-column {
        .search-and-actions {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          .search-input {
            flex-grow: 1;
          }

          .action-buttons {
            display: flex;
            gap: 5px;

            button {
              padding: 8px;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;

              &.delete-btn { background-color: #ffebee; }
              &.edit-btn { background-color: #e3f2fd; }
              &.add-btn { background-color: #e8f5e9; }

              .icon {
                font-size: 20px;
              }
            }
          }
        }

        .invoice-table {
          background-color: white;
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 20px;

          .table-header {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            background-color: #3f51b5;
            color: white;
            padding: 10px;

            .col {
              padding: 8px;
            }
          }
        }

        .comments-section {
          textarea {
            width: 100%;
            padding: 8px;
            border: 1px solid #c5cae9;
            border-radius: 4px;
            resize: vertical;
          }
        }
      }

      .right-column {
        .invoice-details {
          background-color: white;
          padding: 20px;
          border-radius: 4px;

          .form-group {
            margin-bottom: 15px;

            label {
              display: block;
              margin-bottom: 5px;
              color: #1a237e;
            }

            input, select {
              width: 100%;
              padding: 8px;
              border: 1px solid #c5cae9;
              border-radius: 4px;
            }
          }

          .totals-section {
            margin-top: 30px;
            border-top: 2px solid #e8eaf6;
            padding-top: 20px;

            .total-row {
              display: flex;
              justify-content: space-between;
              padding: 8px 0;
              border-bottom: 1px solid #c5cae9;

              &:last-child {
                font-weight: bold;
                border-bottom: none;
              }
            }
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;

        &.back-btn {
          background-color: #e8eaf6;
          color: #1a237e;
        }

        &.create-invoice-btn {
          background-color: #1a237e;
          color: white;
        }
      }
    }
  }
} 