@import '../_colors.scss';
@import '../_fonts.scss';

.job-module-container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden; 
}

.job-module {
  flex: 1;
  background-color: $main_contend_pri_color;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  // ---------------------------------------------- title module
  .module-title {
    padding: 2rem 2rem 0rem;

    h1 {
      color: $top_butt_bar_pri_color;
      font-family: $font_base;
      font-size: 2.5rem;
      text-align: left;
    }
  }

  // ---------------------------------------------- Main Container
  .main-container {
    margin-top: 0;
    gap: 0.25rem;
    height: 100%;
    flex: 1;
    display: flex;
    padding: 0.5rem 1rem 0.5rem;
    // aguste para porcentaje de cada columna
    // Ajusta la proporción, asegurando que la primera columna no sea demasiado pequeña
    grid-template-columns: minmax(20%, 200px) 1fr; 
    overflow: hidden;
  }

  // --------------------------------------------------------- Technician Container
  .technician-container {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
    background-color: $table_row_color;
    border-radius: 10px;
    padding: 1rem;
    max-height: 100%;
    
    // --- serach bar styles
    .MuiTextField-root {
      width: 100%;

      .MuiOutlinedInput-root {
        background-color: $table_row_color;
        border-radius: 30px;
        height: 40px;
        padding-right: 0; 
        border-color: darken($table_row_color, 25%);
        
        &:hover {
          border-color: #ffffff;
        }

        .MuiInputBase-input {
          color: black;
        }
      }
    }

    .search-button {
      color: white;
    }

    .technicians-panel {
      margin-top: 1rem;

      h2 {
        color: $top_butt_bar_pri_color;
        margin-bottom: 1rem;
      }

      .technicians-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow-y: auto;
        max-height: calc(100% - 50px);
      }

      .technician-card {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 10px;
        background-color: white;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: $table_row_hover;
        }

        &.selected {
          background-color: $table_row_selected;
        }

        .tech-avatar {
          background-color: $button_weak_pri_color;
        }

        .tech-info {
          flex: 1;

          h3 {
            margin: 0 0 4px 0;
            font-size: 14px;
            color: $table_row_text_color;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      // Add styles for pagination
      .MuiTablePagination-root {
        .MuiTablePagination-selectLabel,
        .MuiTablePagination-displayedRows {
          margin: 0;
          font-size: 12px;
        }
        
        .MuiTablePagination-select {
          margin-right: 8px;
          font-size: 12px;
        }
        
        .MuiTablePagination-actions {
          margin-left: 8px;
        }
      }
    }
  }

  // --------------------------------------------------------- Jobs Container
  .jobs-container {
    flex: 1;
    min-width: 0;
    background-color: $table_row_color;
    border-radius: 10px;
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    max-width: 100%;


    .action-container {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      //background-color: red;
  
      .MuiTextField-root {
        width: 100%;
    
        .MuiOutlinedInput-root {
          background-color: $table_row_color;
          border-radius: 30px;
          height: 40px;
          padding-right: 0; 
          border-color: darken($table_row_color, 25%);
          
          &:hover {
            border-color: #ffffff;
          }
    
          .MuiInputBase-input {
            color: black;
          }
        }
      }
  
      .search-button {
        color: rgb(255, 0, 0);
        color: white;
      }
    
      .edit-button {
        background-color: $button_edit_pri_color;
        border-radius: 5px;
        margin-left: 1rem;
        &:hover {
          background-color: darken($button_edit_pri_color, 25%);
        }
      }
    
      .delete-button {
        background-color: $button_delete_pri_color;
        border-radius: 5px;
        margin-left: 1rem;
        &:hover {
          background-color: darken($button_delete_pri_color, 10%);
        }
      }
    
      .add-button {
        background-color: $button_create_pri_color;
        border-radius: 5px;
        margin-left: 1rem;
        &:hover {
          background-color: darken($button_create_pri_color, 20%);
        }
      }
    
      .MuiIconButton-root img {
        width: 30px;
        height: 30px;
      }
    }

    .jobs-panel {
      margin-top: 0.5rem;
      overflow-y: auto;
      flex: 1;
      margin-bottom: 0;

      .jobs-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        justify-content: center;
        column-gap: clamp(0.75rem, 2vw, 1rem);
        row-gap: 0.5rem; 
        overflow-x: hidden;
      }

      .no-jobs-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
        background-color: white;
        border-radius: 8px;
        padding: 2rem;

        h3 {
          color: $top_butt_bar_pri_color;
          margin-bottom: 1rem;
          font-size: 1.5rem;
        }

        p {
          color: $table_row_text_color;
          font-size: 1rem;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  button {
    background-color: $button_weak_pri_color;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background-color: darken($button_weak_pri_color, 10%);
    }
  }

  span {
    font-size: 1rem;
    color: $table_row_text_color;
  }
}

.edit-popup {
  .edit-popup-content {
    width: 800px;
    max-width: 90vw;
    
    .edit-form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      
      .form-row {
        display: flex;
        gap: 1.5rem;
        
        .form-group {
          flex: 1;
        }
      }
      
      .form-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        
        &.full-width {
          width: 100%;
        }
        
        label {
          font-weight: 500;
          color: $table_row_text_color;
        }
        
        input, select, textarea {
          padding: 0.5rem;
          border: 1px solid darken($table_row_color, 25%);
          border-radius: 4px;
          font-size: 1rem;
          
          &:focus {
            outline: none;
            border-color: $button_weak_pri_color;
          }
        }
        
        textarea {
          resize: vertical;
          min-height: 100px;
        }
      }
    }
  }
}

.edit-button, .delete-button {
  &.Mui-disabled {
    background-color: #cccccc;
    opacity: 0.5;
    cursor: not-allowed;
  }
}