@import '../_colors.scss';
@import '../_fonts.scss';

.home-container {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: $main_contend_pri_color;
}

.home-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;

  h1 {
    font-family: $font_base;
    color: $top_butt_bar_pri_color;
    margin-bottom: 1rem;
    font-size: 2rem;
    text-align: left;
    text-decoration: none;
  }
}
.modules-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
}

.module-card {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  text-align: center;
  text-decoration: none;

  padding: 15px;
  border-radius: 10px;
  background-color: $menu_butt_pri_color;
  width: 19vw;
  height: 31vh;

  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
    background-color: darken($menu_butt_pri_color, 10%);
  }

  .module-name {
    text-align: center;
    color: $menu_butt_icon_pri_color;
    text-decoration: none;
    font-family: $font_base;
    font-size: max(1rem, 2vw);
  }
}

.module-icon {
  width: 15vw;
  height: 15vh;
  margin-bottom: 10px;
  filter: invert(26%) sepia(84%) saturate(1117%) hue-rotate(191deg) brightness(95%) contrast(93%);
}

.module-card:hover .module-icon {
  filter: invert(26%) sepia(84%) saturate(1117%) hue-rotate(191deg) brightness(95%) contrast(93%);
}

.module-name {
  text-align: center;
  font-family: $font_base;
  font-size: 2.5vw;
  font-size: max(1rem, 2vw);
  text-decoration: none;
}

