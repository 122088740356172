@import '../_colors.scss';
@import '../_fonts.scss';

.user-module-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.user-module {
  flex: 1;
  padding: 2rem;
  background-color: $main_contend_pri_color;
  
  h1 {
    color: $top_butt_bar_pri_color;
    font-family: $font_base;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: left;
  }
  
  // ------------- Estilos para el serach
  .action-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .MuiTextField-root {
      width: 100%;
  
      .MuiOutlinedInput-root {
        background-color: $table_row_color;
        border-radius: 30px;
        height: 40px;
        padding-right: 0; 
        border-color: darken($table_row_color, 25%);
        
        &:hover {
          border-color: #ffffff;
        }
  
        .MuiInputBase-input {
          color: black;
        }
      }
    }

    .search-button {
      color: rgb(255, 0, 0);
      color: white;
    }
  
    .edit-button {
      background-color: $button_edit_pri_color;
      border-radius: 5px;
      margin-left: 1rem;
      &:hover {
        background-color: darken($button_edit_pri_color, 25%);
      }
    }
  
    .delete-button {
      background-color: $button_delete_pri_color;
      border-radius: 5px;
      margin-left: 1rem;
      &:hover {
        background-color: darken($button_delete_pri_color, 10%);
      }
    }
  
    .add-button {
      background-color: $button_create_pri_color;
      border-radius: 5px;
      margin-left: 1rem;
      &:hover {
        background-color: darken($button_create_pri_color, 20%);
      }
    }
  
    .MuiIconButton-root img {
      width: 30px;
      height: 30px;
    }
  }
  // ------------- Estilos para la tabla
  .user-table-container {
    max-height: 500px;
    overflow-y: auto;
    border-radius: 10px;
  
    .user-table {
      border-collapse: separate;
    }
  
    .MuiTableHead-root {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: $table_header;
    }
  
    .MuiTableCell-root {
      padding: 18px;
      color: $table_header_text;
    }
  }

  // encabezados de la tabla
  .MuiTableHead-root {
    background-color: $table_header;

    .MuiTableCell-root {
      color: $table_header_text;
      font-weight: bold; 
      font-size: 1.1rem;
      padding: 18px;
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      background-color: $table_row_color;
      cursor: pointer;

      &:hover {
        background-color: $table_row_hover;
      }

      &.selected {
        background-color: $table_row_selected;
      }

      .MuiTableCell-root {
        color: $table_row_text_color;
        border-bottom: none;
        font-size: 1rem;
        padding: 9px;
      }
      
      .MuiTableCell-root {
        padding-left: 18px;
      }

      .role-badge span{
        background-color: $user_role_badge_color;
        color: $user_role_badge_text_color;
        padding: 0.3rem 0.8rem;
        border-radius: 15px;
        display: inline-block;
      }
    }
  }
}



.edit-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .edit-popup-content {
    background-color: $main_contend_pri_color;
    padding: 2rem;
    border-radius: 10px;
    width: 400px;
    max-height: 80vh;
    overflow-y: auto;

    h2 {
      color: $top_butt_bar_pri_color;
      margin-bottom: 1rem;
    }

    .edit-form {
      .form-group {
        margin-bottom: 1rem;

        label {
          display: block;
          margin-bottom: 0.5rem;
          color: $top_butt_bar_pri_color;
        }

        input, select {
          width: 100%;
          padding: 0.5rem;
          font-size: 1rem;
          border: 1px solid $menu_butt_pri_color;
          border-radius: 5px;
        }
      }
    }

    .edit-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;

      button {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 1rem;
      }

      .cancel-btn {
        background-color: #f0f0f0;
        color: #333;
      }

      .save-btn {
        background-color: $top_butt_bar_pri_color;
        color: white;
      }
    }
  }
}

.delete-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .delete-alert-content {
    background-color: #003366;
    color: white;
    padding: 2rem;
    border-radius: 10px;
    width: 400px;
    text-align: center;

    h2 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
    }

    .delete-buttons {
      display: flex;
      justify-content: center;

      button {
        padding: 0.5rem 2rem;
        font-size: 1rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 0.5rem;
      }

      .cancel-btn {
        background-color: #007bff;
        color: white;
      }

      .ok-btn {
        background-color: #dc3545;
        color: white;
      }
    }
  }
}

.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .confirmation-popup-content {
    background-color: #003366;
    color: white;
    padding: 2rem;
    border-radius: 10px;
    width: 400px;
    text-align: center;

    h2 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
    }

    .ok-btn {
      padding: 0.5rem 2rem;
      font-size: 1rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      background-color: #007bff;
      color: white;
    }
  }
}

.error-message {
  color: #dc3545;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.password-requirements {
  margin-top: 0.5rem;
  font-size: 0.8rem;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    color: #dc3545;
    &.met {
      color: #28a745;
    }
  }
}


